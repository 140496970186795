<template>
	<div class="seminaire_view">
		<h3 class="mt-1 mb-8">
			<v-icon
				large
				color="red"
			>
				mdi-youtube
			</v-icon>
			Vidéos du séminaire
		</h3>
		<v-row>
			<v-col>
				<b>BLEUE</b>
				<br>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/YoQNMYzMbn8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
			<v-col>
				<b>GRISE</b>
				<br>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/5u4SCYoen9E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>			
			</v-col>
			<v-col>
				<b>BLANCHE</b>
				<br>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/mlKy5j4D7hQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<b>JAUNE</b>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/b4AvraWSYBM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
			<v-col>
				<b>NOIRE</b>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/P369hmffox0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
			<v-col>
				<b>ROSE</b>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/2oB8l5sfmLM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<b>VIOLETTE</b>
				<br>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/6ZZf9omuf2s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
			<v-col>
				<b>ROUGE</b>
				<br>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/LWuoipNzeGw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</v-col>
			<v-col>
				<b></b>
				<br>
				<img width="100%" height="315" src="/power-rangers-dance-off.gif">
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-btn 
					depressed 
					to="/"
				>
					Retour
				</v-btn>				
			</v-col>
		</v-row>
	</div>
</template>

<script>
	export default {
		name: 'SeminaireView',
		data: () => ({

		}),
		created: function() {

		},
		methods: {

		},
	};
</script>

<style lang="scss">
	.seminaire_view
	{
		.logo
		{
			width: 30px;
			text-align: left;
		}	
	}

</style>